import { Button, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import {
  MdMeetingRoom,
  MdDirectionsCar,
  MdHouse,
  MdSearch,
  MdArrowOutward,
} from "react-icons/md";
import moment from "moment";

const PlaneIcon = ({ color }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91492 23.9098H25.0816"
      stroke={color}
      stroke-width="2.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.97048 18.2374L2.27954 13.5766C2.84554 13.2498 5.6463 14.2269 6.68893 14.7619L12.3484 12.736L7.5095 4.35481L9.90969 4.21075L17.7267 11.4799L22.5394 9.95645C24.6708 9.34675 25.3429 10.511 25.4772 10.7435C26.2837 12.1405 24.654 13.0814 24.4212 13.2158C22.5587 14.2911 4.97048 18.2374 4.97048 18.2374Z"
      fill={color}
      stroke={color}
      stroke-width="2.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ServiceData = [
  {
    name: "Booking Room",
    id: 2,
    link: "/booking-room",
    icon: <MdMeetingRoom size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "Booking Car",
    id: 3,
    link: "/booking-car",
    icon: <MdDirectionsCar size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "Booking Mess",
    id: 1,
    link: "/parastay",
    icon: <MdHouse size="28px" color="#184973" />,
    active: true,
  },
  {
    name: "Paratrip",
    link: process.env.REACT_APP_PARATRIP_URL,
    isOtherApp: true,
    icon: (
      <PlaneIcon
        size="28px"
        color={
          moment(new Date()).format("YYYY-MM-DD") >=
          process.env.REACT_APP_PARATRIP_DATE
            ? "#184973"
            : "#A5A5A5"
        }
      />
    ),
    active:
      moment(new Date()).format("YYYY-MM-DD") >=
      process.env.REACT_APP_PARATRIP_DATE,
  },
  {
    name: "Search",
    link: "/",
    icon: <MdSearch size="28px" color="#A5A5A5" />,
    active: false,
  },
];

function ServiceList() {
  const navigate = useHistory();

  return (
    <Grid templateColumns="repeat(3,1fr)" padding="4px 16px" gap="16px">
      {ServiceData.map((service, i) => (
        <GridItem maxHeight="80px">
          <Button
            key={i}
            isDisabled={!service.active}
            style={{
              boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
              borderRadius: "12px",
            }}
            w="100%"
            h="100%"
            padding="0"
            maxHeight="80px"
            bg={service.active ? "#F0F6FF" : "#EFEFEF"}
            onClick={() => {
              if (service.active) {
                if (service.isOtherApp) {
                  window.open(service.link);
                } else {
                  localStorage.setItem("appId", service.id);
                  navigate.push(service.link);
                }
              }
            }}
          >
            <Flex
              w="100%"
              h="100%"
              padding="10px 8px"
              direction="column"
              rowGap="8px"
              borderRadius="12px"
            >
              <Flex justifyContent="space-between">
                {service.icon}
                {service.isOtherApp && service.active && (
                  <MdArrowOutward size="20px" color="#184973" />
                )}
              </Flex>
              <Text
                fontSize="12px"
                fontWeight="400"
                textAlign="left"
                style={{ textWrap: "wrap" }}
              >
                {service.name}
              </Text>
            </Flex>
          </Button>
        </GridItem>
      ))}
    </Grid>
  );
}

export default ServiceList;
